.App {
  text-align: center;
}

.App-logo {
  height: 21vmin;
  pointer-events: none;
  user-select: none;
  margin-left: 12px;
  margin-right: 12px;
}



.App-header {
  background-color: black;
  min-height: 9vh;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 1vmin);
  color: white;
  font-family: 'Times New Roman', Times, serif;
  user-select: none;
}

.App-header h2 {
  font-size: calc(12px + 2vmin);
}

.App-header button {
  font-size: calc(12px + 2vmin);
  border: 1px solid white;
  background-color: black;
  color: white;
  padding: 3px 9px;
  cursor: pointer;
  margin-left: 15px;
  transition: 0.72s;
  margin-right: 30px;
}

.App-header button:hover {
  background-color: white;
  color: black;
  border: 1px solid black;
  margin-left: 27px;
}


.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
